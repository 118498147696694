import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
//import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
//import UserProvider from "./contexts/UserProvider";
import API from "./utils/API";
import UserContext from "./UserContext";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
//const LandingComponent = lazy(() => import("./landing/components/Main"));
class App extends React.Component {
  constructor(props) {
    super(props); // first command to run in constructor

    this.postUserLogin = (userData) => {
      console.log(
        `Start User postUserLogin with data ${JSON.stringify(userData)}`
      );
      if (userData) {
        API.postUserLogin(userData, (err, res) => {
          console.log(
            `Return from API.postUserLogin ERR: ${JSON.stringify(
              err
            )}  RES: ${JSON.stringify(res)} `
          );
          if (err === true) {
            return console.log("an error occurred failed to log user in.");
          }
          this.setState({ user: res.user });
        });
      }
    };
    this.postUserSignUp = (signUpData) => {
      console.log(
        `Start User postUserLogin with data ${JSON.stringify(signUpData)}`
      );

      if (signUpData) {
        API.postUserLogin(signUpData, (err, res) => {
          console.log(
            `Return from API.postUserLogin ERR: ${JSON.stringify(
              err
            )}  RES: ${JSON.stringify(res)} `
          );
          if (err === true) {
            return console.log("an error occurred failed to log user in.");
          }
          this.setState({ user: res.user });
        });
      }
    };
    this.getUserLogout = (event) => {
      event.preventDefault();
      API.getLoggedOut().then(this.getUserStatus);
    };

    this.getUserStatus = () => {
      //console.log(`Hello, this is GetUIserStatus()`);
      API.getLoginStatus().then((res) => {
        //console.log(`Finally gets resolved`);
        //console.log(`this.getUserStatus gets resolved with RES: ${JSON.stringify(res)}`);
        if (res) {
          this.setState({ user: res.user });
          //this.props.history.push('/c');
        }
      });
    };
    //postResponse
    this.postResponse = (responseData) => {
      console.log(
        `Start postResponse with data ${JSON.stringify(responseData)}`
      );
      if (responseData) {
        API.postNewResponse(responseData, (err, res) => {
          console.log(
            `Return from API.postNewResponse ERR: ${JSON.stringify(
              err
            )}  RES: ${JSON.stringify(res)} `
          );
          if (err === true) {
            return console.log("an error occurred failed to log user in.");
          }
          //this.setState({ user: res.user });
          console.log(`Silenced setState from this[App].postResponse `);
        });
      }
    };

    this.state = {
      user: {
        access_id: 0,
        type: "Guest",
        user_id: 0,
        counselor_id: 0,
        username: "guest",
        email: "info@mypickmeup.com",
        phone_number: "571-421-4738",
      },
      getUserStatus: this.getUserStatus,
      getUserLogout: this.getUserLogout,
      postUserLogin: this.postUserLogin,
      postResponse: this.postResponse,
      //loading: true
    };
  }

  componentDidMount() {
    //console.log(`Initial this.state in componentDidMount ${JSON.stringify(this.state)}`);
    if (this.state.user.access_id === 0) {
      //console.log(`ComponentdidMount from App.js `);
      this.getUserStatus();
      //console.log(`finished get user status ${this.state.user.access_id} `);
      //this.postUserLogin({username: "ian@mypickmeup.com", password:"iloveiza"});
    }
  }
  render() {
    const { user } = this.state;
    if (user === undefined) return null;
    //const {  user: {access_id= 0, type= 'Guest',user_id= 0,username= 'guest'} } = this.state || {};
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <UserContext.Provider value={this.state}>
              <Switch>
                <Route path="/c">
                  <LoggedInComponent />
                </Route>
                <Route>
                  <LoggedOutComponent />
                </Route>
              </Switch>
             
            </UserContext.Provider>
          </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}
export default App;
