import React from 'react'

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const UserContext = React.createContext({
  user: {
    access_id: 0,
    type: 'Guest',
    user_id: 0,
    counselor_id: 0,
    username: 'guest',
    email: 'info@mypickmeup.com',
    phone_number: '571-421-4738',
  },
  getUserStatus: () => {},
  postUserLogin: () => {},
  postUserSignUp: () => {},
  postResponse: () => {}, 
  getUserLogout: () => {}
})
export default UserContext
