import axios from 'axios'
/* eslint-disable no-console */
//const url = 'http://localhost:8080';
const url = 'https://api-dot-pmu-app.ue.r.appspot.com';
//https://api-dot-pmu-app.ue.r.appspot.com/api/user/counselor
export default {
  getLoginStatus: async () => {
    //console.log("getLoginStatus from API context call");
    try {
      const res = await axios.get(url +'/api/login/status')
      //const resource = await axios.get('/api/login/status/testParamFromgetLoginStatus')
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },
  postUserLogin: async (user, done) => { 
    try {
      //const resource = await axios.get('/api/login/status/')
      console.log(`user object sent to postUserLogin ${user}`);
      const res = await axios.post(url +'/api/login', user)
      // Async function for logging in, setting up callback to return two params, ( error: false if no error, res.data: userData from server )
      //return (res.data.user.username ? (done(false, res.data)) : (done(false, 'error logging in')))
      return (res.data.user.email ? (done(false, res.data)) : (done(false, 'error logging in')))
    } catch (err) {
      console.log('serverside error thrown failed log in attempt')
      return done(true, false)
    }
  },
  getLoggedOut: async () => {
    try {
      const res = await axios.get(url +'/api/logout')
      return res
    } catch (err) {
      return console.log(err)
    }
  },
  
  postNewUser: async (newUser) => { // Example POST: { "vals": ["test_user", "111111", 1] }
    console.log(newUser)
    try {
      const { username, password, access_id } = newUser
      const res = await axios.post(url +'/api/user', {
        vals: [username, password, access_id]
      })
      console.log(res)
      return res
    } catch (err) {
      return console.log(err)
    }
  },
  getAllUsers: async () => {
    try {
      const res = await axios.get(url +'/api/user')
      console.log(res)
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },
  deleteUserById: async (id) => {
    try {
      const res = await axios.delete(`${url}/api/user/${id}`)
      console.log(res)
      return res
    } catch (err) {
      return console.log(err)
    }
  },
  /**
   * 
   * {
        "vals": [ "Ian Reyes", "+15714214738", "22030", "1999-03-19"]
      }
   */
  postNewResponse: async (newResponse) => { // Example POST: { "vals": ["test_user", "111111", 1] }
    console.log(newResponse)
    try {
      const { name, phone, zip, birthday } = newResponse
      const res = await axios.post(url +'/api/response', {
        vals: [name, phone, zip, birthday]
      })
      console.log(res)
      return res
    } catch (err) {
      return console.log(err)
    }
  },
  getAllResponses: async () => {
    try {
      const res = await axios.get(url +'/api/response')
      console.log(res)
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },
}
